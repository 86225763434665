import { createApp } from 'vue'
import axios from 'axios'
import router from './router'
import store from './store'

import App from './App.vue'
import 'easymde/dist/easymde.min.css'
const CODE = '027C4319114A58BD'
axios.defaults.baseURL = 'http://apis.imooc.com/api/'
axios.interceptors.request.use(config => {
  // GET请求
  config.params = { ...config.params, icode: CODE }
  // 上传文件
  if (config.data instanceof FormData) {
    config.data.append('icode', CODE)
  } else {
    config.data = { ...config.data, icode: CODE }
  }
  store.commit('setLoading', true)
  store.commit('setError', { status: false, message: '' })
  return config
})

axios.interceptors.response.use(config => {
  store.commit('setLoading', false)
  return config
}, e => {
  const { error } = e.response.data
  store.commit('setError', { status: true, message: error })
  store.commit('setLoading', false)
  return Promise.reject(e.response.data)
})
const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
