import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["dropdown-option", {'is-disabled': _ctx.disabled}]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleItemClick && _ctx.handleItemClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}