import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c0e12990"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "post-list" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row my-3 align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "col-4"
}
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
      return (_openBlock(), _createElementBlock("article", {
        key: post._id,
        class: "card mb-3 shadow-sm"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, [
            _createVNode(_component_router_link, {
              to: `/posts/${post._id}/`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(post.title), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            (post.image && typeof post.image !== 'string')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: post.image.fitUrl,
                    alt: post.title,
                    class: "rounded-lg w-100"
                  }, null, 8, _hoisted_5)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("p", {
              class: _normalizeClass([{'col-8': post.image}, "text-muted"])
            }, _toDisplayString(post.excerpt), 3)
          ]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(post.createdAt), 1)
        ])
      ]))
    }), 128))
  ]))
}