
import { defineComponent } from 'vue'
import { emitter } from './Dropdown.vue'
export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    closeAfterClick: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const handleItemClick = (e: MouseEvent) => {
      emitter.emit('dropdown-item-clicked', { e, props })
    }
    return {
      handleItemClick
    }
  }
})
