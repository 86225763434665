import { vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "validate-input-container pb-3 position-relative" }
const _hoisted_2 = {
  key: 2,
  class: "invalid-feedback position-absolute mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tag !== 'textarea')
      ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
          key: 0,
          class: ["form-control", {'is-invalid': _ctx.inputRef.error}],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputVal) = $event)),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args)))
        }, _ctx.$attrs), null, 16)), [
          [_vModelDynamic, _ctx.inputVal]
        ])
      : _withDirectives((_openBlock(), _createElementBlock("textarea", _mergeProps({
          key: 1,
          class: ["form-control", {'is-invalid': _ctx.inputRef.error}],
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputVal) = $event)),
          onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args)))
        }, _ctx.$attrs), "\n    ", 16)), [
          [_vModelText, _ctx.inputVal]
        ]),
    (_ctx.inputRef.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.inputRef.message), 1))
      : _createCommentVNode("", true)
  ]))
}