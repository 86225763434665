
import { defineComponent, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { GlobalDataProps } from '../store'
import ColumnList from '../components/ColumnList.vue'
import { objToArr } from '../helper'
import useLoadMore from '../hooks/useLoadMore'

export default defineComponent({
  name: 'Home',
  components: {
    ColumnList
  },
  setup() {
    const store = useStore<GlobalDataProps>()
    const router = useRouter()
    const totalColumns = computed(() => store.state.columns.total || 0)
    const currentPage = computed(() => store.state.columns.currentPage || 0)
    onMounted(() => {
      store.dispatch('fetchColumns')
    })
    const list = computed(() => objToArr(store.state.columns.data))
    const { loadMorePage, isLastPage } = useLoadMore('fetchColumns', totalColumns, { currentPage: currentPage.value }, 6)

    const createOrLogin = () => {
      const isLogin = store.state.user.isLogin
      if (!isLogin) {
        router.push('/login')
      } else {
        router.push('/create')
      }
    }
    return {
      list,
      loadMorePage,
      isLastPage,
      totalColumns,
      createOrLogin
    }
  }
})
