import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#message" }, [
    (_ctx.isVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["alert message-info fixed-top w-50 mx-auto d-flex justify-content-between mt-2", _ctx.classObject])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.message), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)), ["prevent"])),
            "aria-label": "Close"
          })
        ], 2))
      : _createCommentVNode("", true)
  ]))
}