
import { defineComponent, ref, watch, onUnmounted } from 'vue'
import mitt from 'mitt'
import useClickOutside from '../hooks/useClickOutside'
export const emitter = mitt()
export default defineComponent({
  name: 'Dropdown',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  emits: ['item-clicked'],
  setup(props, context) {
    const isOpen = ref(false)
    const dropdownRef = ref<null | HTMLElement>(null)
    const toggleOpen = () => {
      isOpen.value = !isOpen.value
    }
    const dropDownItemClicked = (e: any) => {
      if (e.props.closeAfterClick) {
        isOpen.value = false
      }
      context.emit('item-clicked', e)
    }
    emitter.on('dropdown-item-clicked', dropDownItemClicked)
    onUnmounted(() => {
      emitter.off('dropdown-item-clicked', dropDownItemClicked)
    })
    const isClickOutside = useClickOutside(dropdownRef)

    watch(isClickOutside, () => {
      if (isOpen.value && isClickOutside.value) {
        isOpen.value = false
      }
    })
    return {
      isOpen,
      toggleOpen,
      dropdownRef
    }
  }
})
